<template>
  <div class="mobilePage pcm-mobilePage">
    <div class="main">
      <!-- 头部 -->
      <div class="headerBox">
        <div class="androidLogoBox">
          <div class="logo"></div>
          <!--          <div class="appName"></div>-->
        </div>
        <div class="linkUsBox">
          <img class="linkUs" src="@/assets/images/mobile1/linkUs.png" />
          <img
            class="patato"
            @click="toPotato"
            src="@/assets/images/mobile1/patato.png"
          />
          <img
            class="telegram"
            @click="toTg"
            src="@/assets/images/mobile1/telegram.png"
          />
        </div>
        <!--        <div class="officialBtn" @click="toTg"></div>-->
      </div>
      <div style="position: relative">
        <van-swipe class="swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item>
            <img class="bg" src="@/assets/images/mobile1/phoneBg1.png" />
            <div class="phoneTitle">
              <img src="@/assets/images/mobile1/phoneTitle1.png" />
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <img class="bg" src="@/assets/images/mobile1/phoneBg2.png" />
            <div class="phoneTitle">
              <img src="@/assets/images/mobile1/phoneTitle2.png" />
            </div>
          </van-swipe-item>
        </van-swipe>
        <!-- 中间页面 -->
        <!--      <div class="swiper-container">-->
        <!--          <div class="swiper-wrapper">-->
        <!--              <div  class="swiper-slide" v-for="(item, index) in 2" :key="index">-->
        <!--                <page :pages="index+1" :isChange="isChange"></page>-->
        <!--              </div>-->
        <!--          </div>-->
        <!--      </div>-->
        <!-- 底部按钮 -->
        <!-- 安装详细教程 -->
        <!--            <div class="iosDownload" v-if="os.isPhone">-->
        <!--                <div class="bottom_btns">-->
        <!--                    <img src="@/assets/images/mobile1/lightBtn.png">-->
        <!--                    <img class="footerTip" src="@/assets/images/mobile1/footer_tip.png">-->
        <!--                </div>-->
        <!--      -->
        <!--                <div class="title">温馨提示</div>-->
        <!--                <div class="footerMsg">-->
        <!--                    <img src="@/assets/images/mobile1/footerMsgIos.png">-->
        <!--                </div>-->
        <!--              -->
        <!--            </div>-->

        <div class="iosDownload" v-if="os.isPhone">
          <div class="bottom_btns">
            <img
              @click="getApkInfo('ios')"
              src="@/assets/images/mobile1/lightBtn.png"
            />
            <img
              class="footerTip"
              src="@/assets/images/mobile1/footer_tip.png"
            />
          </div>

          <div class="title">温馨提示</div>
          <div class="footerMsg">
            <img src="@/assets/images/mobile1/footerMsgIos.png" />
          </div>
        </div>

        <div class="androidDownload" v-if="os.isAndroid">
          <div class="bottom_btns">
            <img
              @click="getApkInfo('android')"
              src="@/assets/images/mobile1/lightBtn.png"
            />
            <div class="title">温馨提示</div>
            <img
              @click="$router.push('/noticeDialog')"
              class="footerTip"
              src="@/assets/images/mobile1/linkNotice.png"
            />
          </div>
          <div class="footerMsg">
            <img src="@/assets/images/mobile1/footerMsg.png" />
          </div>
        </div>
      </div>

      <!--            <div class="androidBtn" v-if="os.isAndroid" @click="getApkInfo('android')"></div>-->
      <!--            <div class="iosBtn" v-else @click="getApkInfo('ios')"></div>-->
      <!--            <p class="androidJC" v-if="os.isAndroid"><span @click="isLook = !isLook">安装详细教程 <i-->
      <!--                class="el-icon-arrow-up"></i></span></p>-->
      <!--            <p class="iosJCp" v-else><span @click="isLook = !isLook">安装详细教程 <i-->
      <!--                class="el-icon-arrow-up"></i></span></p>-->
    </div>

    <!--        &lt;!&ndash; 弹框 &ndash;&gt;-->
    <!--        <div class="dialog" v-if="isLook">-->
    <!--            <div class="dialogbox">-->
    <!--                &lt;!&ndash;                <div v-if="os.isAndroid" class="azJC"></div>&ndash;&gt;-->
    <!--                <div class="iosJC"></div>-->
    <!--                <div class="close" @click="isLook = !isLook"></div>-->
    <!--            </div>-->
    <!--        </div>-->

    <div class="dialogItem" v-show="isLook">
      <div class="dialogBox">
        <img src="@/assets/images/mobile1/dialog_light.png" alt="" />
      </div>
      <div class="dialog_close" @click="isLook = false">我知道了</div>
    </div>
  </div>
</template>
<script>
import handleReplaceURLParams from "@/utils/handleReplaceURLParams";
import getApkInfo1 from "@/api/getApkInfo";
// import page from "./page";
import "swiper/swiper-bundle.min.css";
// import Swiper, {Navigation, Pagination, Autoplay} from "swiper";

// Swiper.use([Autoplay, Navigation, Pagination]);
export default {
  components: {},
  props: ["os", "switchData", "ip"],
  data() {
    return {
      // height: 0,
      // loop: true,
      // fontColor: "",
      // page_url: "",
      // downloadVisible: false, // 下载弹窗
      isLook: false,
      step1: require("@/assets/images/mobile1/iosStep1.png"),
      step2: require("@/assets/images/mobile1/iosStep2.png"),
      step3: require("@/assets/images/mobile1/iosStep3.png"),
      dialogBackground: require("@/assets/images/mobile1/dialog.png"),
      isChange: false,
    };
  },
  mounted() {
    // let vm = this
    // new Swiper('.swiper-container', {
    //     // direction: "vertical",
    //     autoplay: {
    //         delay: 2000,
    //         disableOnInteraction: false,
    //     },
    //     loop: true, // 循环模式选项
    //     on: {
    //         slideChangeTransitionEnd: function (data) {
    //             vm.isChange = true
    //             data.$el[0].getElementsByClassName('swiper-slide-duplicate')[1].children[0].children[0].children[0].children[0].setAttribute('class', 'people1 scalBig')
    //             data.$el[0].getElementsByClassName('swiper-slide-duplicate')[0].children[0].children[0].children[0].children[0].setAttribute('class', 'people3 scalBig')
    //         },
    //         slideChangeTransitionStart: function (data) {
    //             data.$el[0].getElementsByClassName('swiper-slide-duplicate')[1].children[0].children[0].children[0].children[0].setAttribute('class', 'people1')
    //             data.$el[0].getElementsByClassName('swiper-slide-duplicate')[0].children[0].children[0].children[0].children[0].setAttribute('class', 'people3')
    //             vm.isChange = false
    //         },
    //     },
    // })
  },
  created() {
    this.getUrl();
  },
  methods: {
    // joinGroup() {
    //   window.location.href = "https://lynnconway.me/sis66";
    // },
    //跳转到应用中心
    // toApplication() {
    //   if (
    //     this.page_url.indexOf("SRNA") == -1 &&
    //     this.page_url.indexOf("DYFLH") == -1
    //   ) {
    //     window.location.href = "http://pf.plus";
    //   }
    // },
    // 商店包页面跳转
    // handleRouteGoIosBeta() {
    //   if (
    //     this.switchData.iosDown &&
    //     !this.switchData.iosTF &&
    //     !this.switchData.iosMdm &&
    //     !this.switchData.iosH5
    //   ) {
    //     this.$router.push({
    //       path: "/iosEnterprise",
    //       query: { ...this.$route.query, ...{ ip: this.ip } }
    //     });
    //   }
    //   if (
    //     !this.switchData.iosDown &&
    //     !this.switchData.iosTF &&
    //     !this.switchData.iosMdm &&
    //     this.switchData.iosH5
    //   ) {
    //     this.isSafari();
    //   } else {
    //     this.$router.push({
    //       path: "/iosBeta",
    //       query: {
    //         ...this.$route.query,
    //         ...{ ip: this.ip },
    //         ...{ switchData: window.btoa(JSON.stringify(this.switchData)) }
    //       }
    //     });
    //   }
    // },
    getUrl() {
      this.page_url = window.location.href;
    },
    getApkInfo(type) {
      if (type === "android") {
        setTimeout(async () => {
          this.$emit("getApkInfo", type);
        }, 0);
        // this.$router.push({
        //   path: "/tutorial_android",
        //   query: this.$route.query
        // });
      } else if (type === "ios") {
        setTimeout(async () => {
          this.$emit("getApkInfo", type);
        }, 400);
        this.isLook = true;

        // this.$router.push({
        //   path: "/yindaoPage"
        // });
      }
    },
    //获取H5下载链接
    getiosBeta() {
      var params = handleReplaceURLParams()
        ? handleReplaceURLParams()
        : JSON.stringify(this.$route.query) === "{}"
        ? "{}"
        : JSON.stringify(this.$route.query);
      const param = {
        appId: 18,
        ip: this.$route.query.ip ? this.$route.query.ip : "", //获取ip地址
        channel: JSON.stringify({
          ...JSON.parse(params),
          ...this.cutInfo,
        }),
        domain: window.location.protocol + "//" + window.location.host,
        terminal: "ios",
        packageType: 5,
      };
      getApkInfo1(param).then((res) => {
        if (res.data.code === 200) {
          // H5
          var url = res.data.data.link;
          let dc = this.$route.query.dc;
          let ch = this.$route.query.ch;
          let pc = this.$route.query.pc;
          let linkUrl = "";
          if (dc) {
            linkUrl = url + "?dc=" + dc;
          } else if (ch) {
            linkUrl = url + "?ch=" + ch;
          } else if (pc) {
            linkUrl = url + "?pc=" + pc;
          } else {
            linkUrl = url;
          }
          this.$router.push({
            name: "h5_ios",
            query: Object.assign(this.$route.query, {
              linkUrl: window.btoa(linkUrl),
            }),
          });
        }
      });
    },
    // isSafari() {
    //   var ua = navigator.userAgent.toLowerCase();
    //   if (
    //     ua.indexOf("applewebkit") > -1 &&
    //     ua.indexOf("mobile") > -1 &&
    //     ua.indexOf("safari") > -1 &&
    //     ua.indexOf("linux") === -1 &&
    //     ua.indexOf("android") === -1 &&
    //     ua.indexOf("chrome") === -1 &&
    //     ua.indexOf("ios") === -1 &&
    //     ua.indexOf("browser") === -1
    //   ) {
    //     this.getiosBeta();
    //     return true;
    //   } else {
    //     this.downloadVisible = true;
    //     return false;
    //   }
    // },
    // 关闭弹窗
    // handleDownloadClose() {
    //   this.downloadVisible === false;
    // },
    toPotato() {
      window.open("https://ptcc.in/wuyuetian_guanfangqun", "_blank");
    },
    toTg() {
      // setTimeout(async () => {
      //     window.location.href = "https://t.me/heiliaoshe";
      // }, 0);

      window.open("https://t.me/qs5yt_app", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.pcm-mobilePage {
  max-width: 750px;
  margin: 0 auto;
  color: #fff;
}

.mobilePage {
  overflow-y: auto;
  height: 100%;
  background: #000 !important;
  -webkit-overflow-scrolling: touch;

  .dialogItem {
    background-image: url("../../../assets/images/mobile1/dialog.png");
    // background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    // background-origin: content-box;
    // overflow: hidden;
    width: 85%;
    // height: 500px;
    position: fixed;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .dialogBox {
      padding: 0 0.4rem;
      height: 64%;
      overflow: scroll;
      margin-top: 1.5rem;
      font-size: 12px;
    }

    .dialog_close {
      width: 75%;
      line-height: 40px;
      background: #ff9f19;
      text-align: center;
      color: #fff;
      //margin: 0.2rem auto 0;
      font-size: 20px;
      border-radius: 50px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }

  .main {
    //width: 100%;
    //height: 100%;
    //overflow: hidden;
    //background-size: 130%;
    //background-position: center;
    //background-repeat: no-repeat;
  }

  .main::before {
    width: 100%;
    content: "";
    height: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: rgba(0, 00, 0, 0.8);
  }

  // 头部
  .headerBox {
    //width: 100%;
    //display: flex;
    //justify-content: end;
  }

  .swipe {
    //height: 500px;

    .van-swipe-item {
      //background: red;
      width: 100%;
      height: calc(100vw / 0.461);

      .bg {
        width: 100%;
      }

      .phoneTitle {
        position: absolute;
        top: 250px;
        left: 0;
        display: flex;
        justify-content: center;

        img {
          width: 80%;
        }
      }
    }
  }

  .androidLogoBox {
    position: fixed;
    z-index: 88;
    top: 10px;
    right: 27px;
    display: flex;
    //margin: 10px 0 0 20px;

    .logo {
      width: 50px;
      height: 50px;
      background: url("../../../assets/images/mobile1/logo.png") no-repeat
        center;
      background-size: 100% 100%;
      border-radius: 10px;
    }

    .appName {
      width: 100px;
      height: 59px;
      background: url("../../../assets/images/mobile1/logoName.png") no-repeat
        center;
      background-size: 100% 100%;
    }
  }

  .linkUsBox {
    position: fixed;
    z-index: 88;
    top: 100px;
    right: 16px;
    display: flex;
    align-items: center;

    .linkUs {
      width: 60px;
    }

    .patato {
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }

    .telegram {
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }
  }

  .iosDownload {
    position: absolute;
    top: 420px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;
    width: 90%;

    .bottom_btns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 82%;
      margin: 0 auto;
    }

    .footerTip {
      margin-top: 10px;
    }

    .title {
      font-size: 16px;
      font-weight: 800;
      margin-top: 20px;
    }

    .footerMsg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      img {
        width: 100%;
      }
    }
  }

  .androidDownload {
    position: absolute;
    top: 420px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;
    width: 90%;

    .bottom_btns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 82%;
      margin: 0 auto;
    }

    .footerTip {
      margin-top: 10px;
    }

    .title {
      font-size: 16px;
      //font-weight: 800;
      margin-top: 15px;
    }

    .footerMsg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      img {
        width: 100%;
      }
    }
  }

  .officialBtn {
    width: 106px;
    height: 32px;
    margin: 30px 20px 0 0;
    background: url("../../../assets/images/mobile1/newofficialBtn.png")
      no-repeat center;
    background-size: 100% 100%;
  }

  // 底部
  .androidJC {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 30px;
    font-size: 16px;
    z-index: 10;
  }

  .androidBtn {
    width: 180px;
    height: 45px;
    background: url("../../../assets/images/mobile1/androidBtn.png") no-repeat
      center;
    background-size: 100% 100%;
    position: fixed;
    margin: 10px 0 20px 0;
    z-index: 10;
    bottom: 50px;
    left: 50%;
    margin-left: -90px;
  }

  .message {
    width: 325px;
    height: 60px;
    background: url("../../../assets/images/mobile1/message.png") no-repeat
      center;
    background-size: 100% 100%;
    position: fixed;
    left: 7%;
    bottom: 0;
    z-index: 9;
  }

  .iosBtn {
    width: 180px;
    height: 45px;
    background: url("../../../assets/images/mobile1/iosBtn.png") no-repeat
      center;
    background-size: 100% 100%;
    margin: 10px 0 20px 0;
    position: fixed;
    z-index: 10;
    bottom: 50px;
    left: 50%;
    margin-left: -90px;
  }

  .iosJCp {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 30px;
    font-size: 16px;
    z-index: 10;
  }

  //// 中间部分
  //.swiper-container, .swiper-wrapper, .swiper-slide {
  //    width: 100%;
  //    height: 100%;
  //}

  // 弹框
  .dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dialogbox {
    position: fixed;
    z-index: 1200;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .iosJC {
      width: 321px;
      height: 408px;
      background: url("../../../assets/images/mobile1/iosjc.png") center
        no-repeat;
      background-size: 100% 100%;
    }

    .azJC {
      width: 301px;
      height: 332px;
      background: url("../../../assets/images/mobile1/azjc.png") center
        no-repeat;
      background-size: 100% 100%;
    }

    .close {
      margin: 15px 0 0 0;
      width: 31px;
      height: 31px;
      background: url("../../../assets/images/mobile1/close.png") center
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
