<template>
  <div class="pcm-wrapper">
    <div class="logoBox">
      <div class="logo"></div>
      <div class="logo2"></div>
<!--      <div class="appName"></div>-->
    </div>
    <div class="contactDetails">
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
<!--      <div class="twitter" @click="toTwitter"></div>-->
<!--      <div class="menu"></div>-->
    </div>

    <div class="infoBox">
        <img class="tip" src="@/assets/images/pc/contentTitle.png">
        <div class="btns">
            <div class="qrcode" :class="isDownload?'bicode':''">
                <img class="qrcodeImg" :src="qrImg" alt="" />
            </div>
            <div class="btncheck">
                <div class="andBtn" @click="download">
                    <img src="@/assets/images/pc/appleIcon.png">
                    <span>推荐使用UC浏览器扫码</span>
                </div>
                <div class="andBtn" @click="download">
                    <img src="@/assets/images/pc/andIcon.png">
                    <span>推荐使用UC浏览器扫码</span>
                </div>
            </div>
        </div>
<!--      <div class="btnBox">-->
<!--        <div class="iosBtn" :style="{backgroundImage:'url('+ iosBtnImg +')'}" @click="viewQr('ios')"></div>-->
<!--        <div class="androidBtn" :style="{backgroundImage:'url('+ androidBtnImg +')'}" @click="viewQr('android')"></div>-->
<!--        <div class="desc">推荐使用uc浏览器扫码</div>-->
<!--      </div>-->
<!--      <img v-if="iosQr" class="iosQrcode" :src="qrImg" alt="" />-->
<!--      <img v-if="androidQr" class="qrcode" :src="qrImg" alt="" />-->
    </div>
      
      <div class="footer">
          <img src="@/assets/images/pc/footerMsg.png">
      </div>
  </div>
</template>
<script>
export default {
  props: ["qrImg", "os"],
  data() {
    return {
        isDownload: false,
      iosQr: false,
      androidQr: false,
      iosBtnImg: require("@/assets/images/pc/iosBtn.png"),
      androidBtnImg: require("@/assets/images/pc/androidBtn.png")
    };
  },
  mounted() {},
  destroyed() {
    clearTimeout(this.et);
  },
  methods: {
      download(){
          this.isDownload = true;
      },
    getApkInfo(type) {
      this.$emit("getApkInfo", type);
    },
    //tg
    toTg() {
        window.open('https://t.me/qs5yt_app', '_blank');
      // window.location.href = "https://t.me/heiliaoshe1";
    },
    toPotato() {
        window.open('https://ptcc.in/wuyuetian_guanfangqun', '_blank');
      // window.location.href = "https://ptcc.in/wuyuetian_guanfangqun";
    },
    toTwitter() {
      window.location.href = "https://twitter.com/heiliaoshe";
    },
    viewQr(type){
      if(type==='ios'){
        this.androidQr = false;
        this.androidBtnImg = require("@/assets/images/pc/androidBtn.png");
        this.iosQr = !this.iosQr;
        if(this.iosQr){
          this.iosBtnImg = require("@/assets/images/pc/iosBtnFocus.png");
        } else {
          this.iosBtnImg = require("@/assets/images/pc/iosBtn.png");
        }
      } else {
        this.iosQr = false;
        this.iosBtnImg = require("@/assets/images/pc/iosBtn.png");
        this.androidQr = !this.androidQr;
        if(this.androidQr){
          this.androidBtnImg = require("@/assets/images/pc/androidBtnFocus.png");
        } else {
          this.androidBtnImg = require("@/assets/images/pc/androidBtn.png");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url("../../../assets/images/pc/bg.png") no-repeat center;
  background-size: 100% 100%;
  background-size: cover;
  .logoBox {
    position: fixed;
    top: 29px;
    left: 58px;
    color: #fff;
    font-size: 39px;
    text-align: center;
    z-index: 10;
    .logo {
      width: 271.15px;
      height: 72.5px;
      background: url("../../../assets/images/pc/logo.png") no-repeat center;
      background-size: 100% 100%;
    }
      
      .logo2{
          position: absolute;
          top: 0;
          //left: 40px;
          border-radius: 10px;
          width: 72.5px;
          height: 72.5px;
          background: url("../../../assets/images/pc/logo1.png") no-repeat;
          background-size: 100% 100%;
      }
    //.appName {
    //  height: 65px;
    //  background: url("../../../assets/images/pc/hls.png") no-repeat center;
    //  background-size: 100% 100%;
    //}
  }
  .contactDetails {
    position: fixed;
    top: 43.5px;
    right: 58px;
    display: flex;
    .telegram {
      width: 43.5px;
      height: 43.5px;
      background: url("../../../assets/images/pc/telegram.png") no-repeat center;
      background-size: 100% 100%;
      margin-left: 30px;
    }
    .potato {
        width: 43.5px;
        height: 43.5px;
      background: url("../../../assets/images/pc/potato.png") no-repeat center;
      background-size: 100% 100%;
        margin-left: 30px;
    }
    .twitter {
        width: 43.5px;
        height: 43.5px;
      background: url("../../../assets/images/pc/twitter.png") no-repeat center;
      background-size: cover;
        margin-left: 30px;
    }
    .menu {
        width: 43.5px;
        height: 43.5px;
      background: url("../../../assets/images/pc/menu.png") no-repeat center;
      background-size: cover;
    }
  }
    
    .infoBox{
        position: fixed;
        bottom: 203px;
        right: 101.5px;
        
        .tip{
            width: 862.75px;
            height: 507.5px;
            margin-right: 75px;
        }
        
        .btns{
            display: flex;
            justify-content: flex-end;
            position: fixed;
            right: 362.5px;
            bottom: 101.5px;

            .qrcode {
                    //width: 210px;
                    //height: 210px;
                    //position: absolute;
                    //left: 400px;
                    //top: 330px;
                    background: #fff;
                    border: 10px solid #ff9f28;

                  }
            .bicode{
                border: 10px solid transparent;
                -o-border-image: linear-gradient(0deg,#f80,#2ed);
                border-image: linear-gradient(0deg,#f80,#2ed);
                border-image-slice: 100;
            }
            .btncheck{
                display: flex;
                align-items: flex-end;
                margin-left: 58px;
                font-size: 12px;
                
                .andBtn{
                    display: flex;
                    flex-flow: column;
                    margin-right: 21.75px;
                    cursor: pointer;
                    
                    img{
                        width: 130px;
                        height: 43.6px;
                    }
                    
                    span{
                        margin-top: 10px;
                        color: #fff;
                    }
                }
            }
        }
    }

  //.infoBox {
  //  position: fixed;
  //  bottom: 280px;
  //  //left: 50%;
  //  //transform: translate(-50%, 0);
  //    right: 140px;
  //  display: flex;
  //  height: 643.6px;
  //  //width: 946.6px;
  //    background: red;
  //  //background: url("../../../assets/images/pc/breakingText.png") no-repeat
  //  //  center;
  //  background-size: 100% 100%;
  //  .qrcode {
  //    width: 164px;
  //    height: 164px;
  //    position: absolute;
  //    left: 400px;
  //    top: 330px;
  //    background: #fff;
  //  }
  //  .iosQrcode {
  //    width: 164px;
  //    height: 164px;
  //    position: absolute;
  //    left: 655px;
  //    top: 360px;
  //    background: #fff;
  //  }
  //  .btnBox {
  //    height: 127px;
  //    width: 540px;
  //    position: relative;
  //    top: 510px;
  //    left: 350px;
  //    .desc {
  //      text-align: center;
  //      font-size: 15px;
  //      color: #fff;
  //      position: absolute;
  //      bottom: 30px;
  //      left: 20px;
  //      letter-spacing: 1px;
  //      transform: rotate(7.5deg);
  //    }
  //    .iosBtn {
  //      position: absolute;
  //      right: 0;
  //      bottom: 0;
  //      width: 311.4px;
  //      height: 103.3px;
  //      // background: url("../../../assets/images/pc/iosBtn.png") no-repeat
  //      //   center;
  //      background-size: 100% 100%;
  //      cursor: pointer;
  //    }
  //    .androidBtn {
  //      position: absolute;
  //      left: 0;
  //      top: 0;
  //      width: 241px;
  //      height: 88px;
  //      background: url("../../../assets/images/pc/androidBtn.png") no-repeat
  //        center;
  //      background-size: 100% 100%;
  //      cursor: pointer;
  //    }
  //    
  //  }
  //}
    
    .footer{
        width: 100%;
        position: fixed;
        bottom: 15px;
        text-align: center;
        padding: 0 50px;
    }
}
</style>
